$grid-columns: 8;
$grid-gutter-width: 24px;

@font-face {
    font-family: 'germania one';
    font-display: auto;
    src: url('/fonts/GermaniaOne-Regular.ttf');
}

@font-face {
    font-family: 'baskerville';
    font-display: auto;
    src: url('/fonts/Baskerville.ttf');
}

@font-face {
    font-family: 'times new roman';
    font-display: auto;
    src: url('/fonts/Times-New-Roman.ttf');
}

@font-face {
    font-family: 'montserrat';
    font-display: auto;
    src: url('/fonts/Montserrat-Regular.otf');
}

@font-face {
    font-family: 'montserrat bold';
    font-display: auto;
    src: url('/fonts/Montserrat-Bold.otf');
}

@font-face {
    font-family: 'numbers';
    font-display: auto;
    src: url('/fonts/numbers.ttf');
}

@font-face {
    font-family: 'work sans';
    font-display: auto;
    src: url('/fonts/WorkSans-Regular.ttf');
}

@font-face {
    font-family: 'press start';
    font-display: auto;
    src: url('/fonts/prstartk.ttf');
}

$gods-body-font: 'montserrat';
$gods-body-bold: 'montserrat bold';
$gods-font: 'germania one';
$work-font: 'work sans';
$number-font: 'numbers';
$font: 'Helvetica Neue';
$font-special: letter-gothic-std, courier-std, arial;
$fallback-font: helvetica;
$fallback-font2: arial;
$fallback-font3: 'Lucida Grande';
$fallback-font4: sans-serif;

// font size variables
$heading-large:  50px;
$heading-small: 35px;
$subheading-large: 32px;
$subheading-small: 24px;
$brand-section-text-large: 30px;
$brand-section-text-small: 24px;
$charter-member-text-large: 2em;
$charter-member-text-small: 1em;
$btn-text-large: 24px;
$btn-text-small: 18px;
$text-large: 32px;
$text-small: 20px;
$lineheight-large: 38px;
$lineheight-small: 30px;

// margin/padding variables
$margin-large: 3.5rem;
$margin-small: 2.5rem;
$margin-text-large: 1.5rem;
$margin-text-small: 1rem;
$padding-large: 3.5rem;
$padding-small: 2.5rem;

// color variables
$border-bottom: 1px solid black;
$inverted-colour: white;
$colour: black;
$background-color: #FFFB00;
$light-grey: #D8D8D8;
$txt-colour: black;
$grey-txt: #5E5E5E;
$copyright: #545454;
$hovered-btn: #555555;
$lnk-hover: $hovered-btn;
$hover-lnk: #EB3A6D;
$btn-colour: white;
$footer-grey: #9F9F9F;
$category-grey: #9B9B9B;
$calculator-grey: #4F4F4F;
$calculator-header: #F5F5F5;
$lighter-grey: #979797;

// General screen sizes
$xs: 375px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// Actual devices
$galaxys5w: 360px;
$galaxys5h: 640px;

$pixel2w: 411px;
$pixel2h: 731px;
$pixel2xlh: 823px;

$iphone5w: 320px;
$iphone5h: 568px;

$iphone678w: 375px;
$iphone678h: 667px;

$iphone678plusw: 414px;
$iphone678plush: 736px;

$iphonexw: 375px;
$iphonexh: 812px;

$ipadw: 768px;
$ipadh: 1024px;

$ipadprow: 1024px;
$ipadproh: 1366px;

$padding0: 0;

$textRight: right;
$textLeft: left;
$textCenter: center;
$textLowercase: lowercase;
$textUppercase: uppercase;

@import '../../utils/vars';

$background-color: #d4383e;
$secondary-color: #ffcc3c;

.super-luck {

    ////////////////// HEADER ///////////////////////

    .header {
        position: fixed;
        width: 100%;
        z-index: 3;
        transition: all .5s ease-in-out;
        background-color: #e0dcdc;
        
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

        .mainMenu {
            display: flex;
            list-style: none;
        }
        
        .mainMenu li a {
            display: inline-block;
            padding: 10px 25px;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: bold;
            transition: 0.1s ease-in;
        }

        .mainMenu li a:hover {
            transform: scale(1.1);    
        }

        .openMenu {
            font-size:1rem;
            margin: 0 20px;
            display: none;
            cursor: pointer;
        }

        .mainMenu .closeMenu , .icons i {
            font-size: 1rem;
            display: none;
            cursor: pointer;
        }

        .logo {
            margin: 5px 10px;
            padding: 10px 70px 10px 10px;
            font-size: 10px;

            img {
                max-width: 125px;
                @media(max-width: $sm){
                    width: 100px;
                }
            }

        }

    }
        @media(max-width: 1200px){

            nav .mainMenu {
                height: 100vh;
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                z-index: 10;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: top 1s ease;
                display: none;
                background: #e0dcdc;
            }

            nav .mainMenu .closeMenu {
                display: block;
                position: absolute;
                top: 20px;
                right: 20px;

            }

            nav .openMenu {
                display: block;
            }

            nav .mainMenu li a:hover {
                background: none;
                font-size: 1.6rem;
            }
            
            .icons i {
                display: inline-block;
                padding: 12px;
            }
        }

    }

    body {
        scroll-padding-top: 70px; 
    }

     ////////////////////////////////// BANNER //////////////////////////////////

    .banner-hero-wrapper {
        width: 100%;
        height: auto;
        background-color: $background-color;

        .banner-wrapper {
            max-width: 1624px;
            margin: 0 auto;
            margin-right: auto;
            margin-left:  auto;
            padding-right: 10px; 
            padding-left:  10px;
            display: flex;
            justify-content: center;
        
            .banner-banner {
                height: 110vh;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                overflow: hidden;
                background-color: $background-color;

                .banner-desktop {
                    width: 100%;
                    height: auto;
                    overflow: hidden;
                    max-width: 100%;
                    text-align: center;
                    display: flex;
                    background-color: '#ffcc3c';
                    
                    @media (max-width: $md) {
                        display: none;
                    }

                    .banner-hero-title {
                        display: none;
                    }
                }
            
                .banner-mobile {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    text-align: center;
                    display: flex;
                    
                    @media (min-width: 769px) {
                        display: none;
                    }

                    .banner-hero-title {
                        display: none;
                    }
                }
            
                .banner-hover-button {
                    position: absolute;
                    top: 93%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-decoration: none;
                    padding: 15px 65px;
                    font-size: 2rem;
                    border-radius: 40px;
                    margin: auto;
                    min-width: 400px;
                    transition: all .1s ease-in-out;
                    font-family: 'work sans';
                    background-color: $secondary-color;
                    
                    &:hover {
                        font-size: 2.1rem;
                    }

                    @media (max-width: $md) {
                        font-size: 1.5rem;
                        padding: 15px 45px;
                        width: 60%;
                        min-width: 60%;

                        &:hover {
                            font-size: 1.6rem;
                        }
                    }

                    @media (max-width: $sm) {
                        width: 80%;
                        min-width: 80%;
                    }

                    @media (max-width: 320px) {
                        font-size: 1.2rem;

                        &:hover {
                            font-size: 1.3rem;
                        }
                    }
                }
            
                img {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                }
            
            }
        }
    }

     ////////////////////////////////// INTRO GALLERY ////////////////////////////////// 

    .intro-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
      
        .intro-titles {
            text-align: center;
            margin: 150px 0 150px 0;

            h2 {
                display: none;
            }
      
            @media screen and (max-width: $lg) {
      
                img {
                    width: 50%;
                }
            }
      
            @media screen and (max-width: $sm) {
      
                img {
                    width: 70%;
                }
            }
        }
      
        .intro-gallery {
            margin: 0 0 150px 0;
            display: flex;
            justify-content: space-between;
            gap: 40px;
            flex-wrap: wrap;
            max-width: 100%;
            border: 8px solid $background-color;
            border-radius: 45px;
            padding: 40px;
      
            @media screen and (max-width: $xl) {
                gap: 30px;
            }
        }
    }

           ////////////////////////////////// GALLERY COMPONENT ////////////////////////////////// 

        .gallery-component {
            position: relative;
            max-width: 100%;
            flex: 1 1 auto;
            width: 20%;
            display: flex;
            flex-direction: column;
            align-content: stretch;
            top: -150px; 
            margin-bottom: -130px;
        
            @media screen and (max-width: $xl) {
                width: calc(100% * (1/2) - 50px);
                flex-wrap: wrap;
                margin: 1rem 0;
            }
        
            @media screen and (max-width: $sm) {
                width: calc(100% * (1) - 50px);
                flex-wrap: wrap;
                margin: 0;
        
            }
        
            .gallery-image-container {
                display: flex;
                justify-content: center;
            }
            .gallery-title {
                transition: all .5s ease-in-out;        

            }
        
            .gallery-text {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 1.5rem;
                flex-grow: 1;
                background-color: transparent;
                color: black;
                font-family: 'work sans';
                
                h3 {
                    text-align: center;
                    font-size: 2rem;
        
                }        
                
                p {
                    font-size: 1.2rem;
                    margin: 0;
                    text-align: center;
                }
            }

            &:hover .gallery-title {
                background-color: $secondary-color;
            }
        }


    //////// ROADMAP ////////////////////////////////// 

    .milestone-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
      
        .milestone-titles {
            text-align: center;
            margin: 150px 0 150px 0;

            h2 {
                display: none;
            }
      
            @media screen and (max-width: $lg) {
        
                img {
                    width: 50%;
                }
            }
      
            @media screen and (max-width: $sm) {
      
                img {
                    width: 70%;
                }
            }
        }
      
        .milestone-milestone {
            display: flex;
            flex-direction: column;
            gap: 40px;
            padding: 20px 40px;
            background-position: contain;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    //////// ROADMAP  COMPONENT //////////////////////////////////

    .milestonebenefits-component {
        position: relative;
        width: 100%;
        margin: 10px 0;
        border-radius: 65px;     
        height: auto;
        padding: 40px 0;
        width: 100%;   
        display: flex;
        justify-content: center;
        align-items: center;
        top: -110px; 
        font-family: 'work sans'; 
        filter: brightness(90%);
        -webkit-box-shadow: 5px 5px 15px -5px #000000; 
        box-shadow: 5px 5px 15px -5px #000000;
    
        @media(max-width: $xl){
            height: auto;
            padding: 40px 0;
        }

        .milestonebenefits-number {
            display: none;
        }
        
        .milestonebenefits-component-text {
            display: flex; 
            flex-direction: column;
            align-items: flex-start; 
            align-content: center;  
            line-height: 2.1rem;
            font-size: 1.9rem;
            width: 65%;
            padding: 10px 0px;
    
            @media(max-width: $md){
                font-size: 1.5rem;
                width: 75%;
            }
    
            @media(max-width: $sm){
                font-size: 1.3rem;
            }
    
            @media(max-width: $iphone5w){
                font-size: 1.2rem;
                line-height: 1.5rem;
            }
        }

        &:hover {
            filter: brightness(100%);
        }
        
    }

     ////////////////////////////////// ABOUT ////////////////////////////////// 

    .about-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left:  auto;
        padding-right: 10px; 
        padding-left:  10px;
        display: flex;
        justify-content: center;

        .about-about {
            width: 80%;
            display: flex;
            align-items: flex-start;
            gap: 80px;
            line-height: 1.7rem;
            margin: 10rem 0;
            padding: 50px 20px;
            border: 8px solid #d4383e;
            border-radius: 45px;

            @media screen and (max-width: $xl) {
                width: 95%;
            }

            @media screen and (max-width: $lg) {
                gap: 40px;
            }

            @media screen and (max-width: $md) {
                flex-direction: column;
                align-items: center;
            }

            .about-image {
                width: 100%;
                max-width: 100%;
                height: 100%;
                padding: 50px 20px 50px 100px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1400px) {
                    padding: 25px 10px 25px 50px;
                    flex: 50%;
                }
                @media screen and (max-width: $lg) {
                    flex: 50%;
                }

                @media screen and (max-width: $md) {
                    flex: 100%;
                    padding: 40px 140px;
                }

                @media screen and (max-width: $sm) {
                    padding: 20px 70px;
                }
            }

            .about-description {
                padding: 20px 20px 20px 0;
                line-height: 2rem;
                
                p {
                    width: 85%;
                    font-size: 1.4rem;
                    margin: 20px 0 40px 0;
                }
                @media screen and (max-width: 1400px) {
                    flex: 100%;
                }

                @media screen and (max-width: $lg) {
                    flex: 100%;

                    p {
                        width: 100%;
                    }
                }

                @media screen and (max-width: $md) {
                    text-align: center;
                    padding: 20px 0;

                }

                .about-title {
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-family: "work sans"
                }
            }
        }
    }

     ////////////////////////////////// ABOUT DESCRIPTION ////////////////////////////////// 

    .about-description {
        font-family: "work sans";

        .about-description-link {
            text-decoration: none;
            padding: 15px 45px;
            font-size: 1.5rem;
            border-radius: 40px;
            margin: auto;
            background-color: $secondary-color;
        
            @media screen and (max-width: $sm) {
                font-size: 1.2rem;
                width: 100%;
                padding: 20px 30px;
            }
            &:hover {
                background-color: $background-color;
                color: $secondary-color;
            }
        }
    }


    ///////////////// FOOTER /////////////////

    .footer {
        padding: 50px 0 5px 0;
        background-color: #e0dcdc;
    
        .wrapper {
            max-width: 1924px;
            margin: 0 auto;
            width: 100%;
            padding: 0 25px 0 15px;
            
            .footerInfo {
                display: flex;
                justify-content: space-between;
                gap: 50px;
    
                li {
                    list-style: none;
                }
    
                a {
                    text-decoration: none;
                }
    
                @media screen and (max-width: $xl) {
                    flex-direction: column;
                    align-items: center;
                }
    
                .logo {
                    padding: 0px 40px;
                    font-size: 2rem;
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-start;
    
                    @media screen and (max-width: $xxl) {
                        padding: 0px 40px;
                    }
    
                    @media screen and (max-width: $xl) {
                        padding: 0px 20px;
                    }
    
                    h4 {
                        margin: 10px;
                    }
                }
            }
    
            .socialMedia {
                display: flex;
                flex-grow: 2;
    
                img {
                    height: 40px !important;
                    width: 40px !important;
                }
    
                a {
                    padding: 0 20px;
                    transition: 0.1s ease-in;
                    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
    
            .nav {
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: bold;
                flex-grow: 4;
                display: flex;
                justify-content: space-between;
    
                ul {
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    
                    @media screen and (max-width: $xl) {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0px;
                    }
    
                    li {
                        @media screen and (max-width: $md) {
                            text-align: center;
                        }
                    }
                }
            }
    
            .copyright {
                text-align: center;
                margin-top: 75px;
    
                p {
                    font-size: 0.8rem;
                }
            }
            
        }
    }
}




@import '../utils/vars';
// @import './nfttemplate/NftTemplate.module.scss';
// @import './gods/Gods.module.scss';
@import './superluck/SuperLuck.module.scss';
@import './macpunks/MacPunks.module.scss';

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 150px;
}

.accordion {
    width: 80%;
    margin: 0 auto;
    margin-top: 42px;
    // stylelint-disable declaration-no-important
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-family: $font, $fallback-font, $fallback-font2;
    margin-bottom: 230px;

    @media screen and (max-width: $md) {
        width: 100%;
    }
}

.accordion__button {
    background: white !important;
    color: black !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;

    p {
        line-height: normal;
    }

    @media screen and (max-width: $md) {
        font-size: 14px;
        display: inherit;
    }
}

.accordion__button::before {
    margin-top: 7px;
}

.accordion__panel {
    line-height: normal;
    padding-top: 0 !important;
    // stylelint-enable declaration-no-important
    @media screen and (max-width: $md) {
        font-size: 14px;
    }
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.rgbShiftBackup {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    width: 100vw;
    overflow: hidden;
    transform-origin: 50% 50%;

    img {
        width: 100%;
        height: 792px;
        object-fit: cover;

        @media screen and (max-width: $md) {
            max-height: 761px;
        }

        @media screen and (max-width: $sm) {
            max-height: 620px;
        }
    }
}

body {
    font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
    color: $colour;
    overflow-x: hidden;
    margin: 0;
    line-height: 1.5;

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .atlwdg-trigger {
        @media screen and (max-width: $md) {
            // stylelint-disable declaration-no-important
            display: none !important;
        }
    }

    .atlwdg-trigger.atlwdg-SUBTLE {
        bottom: unset !important;
        border-bottom: unset !important;
        border-right: unset !important;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        right: -38px !important;
        color: $inverted-colour !important;
        transition: ease-in-out 0.15s all;
        border-radius: 0 !important;
        box-shadow: none !important;
        background-color: $colour !important;
        border-color: $colour !important;

        &:hover {
            background-color: $hover-lnk !important;
            color: $txt-colour !important;
            border-color: $hover-lnk !important;
        }
        // stylelint-enable declaration-no-important
    }

    a {
        text-decoration: underline;
        color: $colour;

        &:hover {
            color: $colour;
        }
    }

    .container,
    .containerBody {
        max-width: percentage(1270 / 1440);
        width: 100%;
        margin-right: auto;
        margin-left: auto;

        @media screen and (min-width: $xxl) {
            max-width: 1366px;
        }

        @media screen and (max-width: $xl) {
            max-width: calc(100% - 30px);
        }

        @media screen and (max-width: $iphone5w) {
            max-width: 290px;
        }
    }

    .containerBody {
        padding: 0;
        padding-top: 3rem;

        @media screen and (max-width: 992px) {
            padding-top: 1rem;
        }

        @media screen and (max-width: $md) {
            padding-top: 0;
        }

        @media screen and (max-width: 360px) {
            padding-top: 0;
            margin-top: -3rem;
        }

        @media screen and (max-width: 415px) {
            padding-top: 0;
            margin-top: 0;
        }
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .btn {
        border-radius: 0;
        background-color: $colour;
        color: $inverted-colour;
        border: none;
        padding: 0;
        text-transform: uppercase;
        font-weight: bold;
        transition: all 500ms;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $hover-lnk;
            color: $colour;
        }

        &:focus {
            outline: solid black;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .navBtn {
        height: 100px;
        letter-spacing: 0.15px;
        color: $colour;
        background-color: $inverted-colour;
        border: 1px solid $colour;
        font-size: 23px;
        padding-left: 46.75px;
        padding-right: 46.75px;

        @media screen and (max-width: $xl) {
            padding-left: 19px;
            padding-right: 19px;
        }

        @media screen and (max-width: $lg) {
            margin-bottom: 12px;
            padding-left: 135px;
            padding-right: 135px;
        }

        @media screen and (max-width: $md) {
            height: 75px;
            font-size: 18px;
            padding-left: 20px;
            padding-right: 20px;
        }

        &.selected,
        &:focus,
        &:hover {
            color: $inverted-colour;
            background-color: $colour;
            border: 6px solid $hover-lnk;
        }
    }

    .legal {
        .banner {
            margin-bottom: 40px;
        }

        .legal-page-nav {
            margin-bottom: 62px;
        }

        p,
        table {
            font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-left: -12px;
    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .colQuarter {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .colQuarterMd {
        flex: 0 0 25%;
        max-width: 25%;

        @media screen and (max-width: $md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .colQuarterThirdsLg {
        max-width: 25%;
        flex: 0 0 25%;

        @media screen and (max-width: $lg) {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);
        }
    }

    .colHalf {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .colHalfLg {
        flex: 0 0 50%;
        max-width: 50%;

        @media screen and (max-width: $lg) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .colThreeQuartersMd {
        flex: 0 0 75%;
        max-width: 75%;

        @media screen and (max-width: $md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .customInfoText {
        display: flex;
        justify-content: left;
        width: 90%;

        @media screen and (max-width: 1025px) {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }
    }

    .colThreeQuartersLg {
        flex: 0 0 75%;
        max-width: 75%;

        @media screen and (max-width: $lg) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .colFullWidth {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .colSmallFour {
        @media screen and (min-width: $sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .colQuarter,
    .colQuarterMd,
    .colQuarterThirdsLg,
    .colHalf,
    .colHalfLg,
    .colThreeQuartersMd,
    .colThreeQuartersLg,
    .colFullWidth,
    .colSmallFour {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
    }

    .mobileDisplayNone {
        @media screen and (max-width: $sm) {
            display: none;
        }
    }

    .displayNoneMembers {
        @media screen and (max-width: $md) {
            display: none;
        }
    }

    .padding {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
    }

    .appear {
        // stylelint-disable declaration-no-important
        opacity: 1 !important;
        transform: translateY(0) !important;
        // stylelint-enable declaration-no-important
    }
    // Custom Modal Styling
    .hubSpotOverlay {
        background-color: $inverted-colour;
        color: $colour;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1000;
        padding: 0;

        img {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 40px;
        }
    }

    .hubSpotModal {
        height: 100%;

        #reactHubspotForm0 {
            background: white;
        }

        max-width: 100%;
        position: absolute;
        background: none;
        width: 100%;
        padding: 0;
        margin: 0 auto;
        font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;

        button {
            right: 12%;
            top: 16%;
            position: absolute;
            background: transparent;
            border: 0;
            opacity: 0.5;

            &:hover {
                opacity: 0.8;
            }
        }

        .react-responsive-modal-closeButton {
            display: none;
        }

        iframe {
            margin: 0 auto;
            margin: auto;
            // stylelint-disable declaration-no-important
            width: 50% !important;
            margin-top: 22vh;

            @media screen and (max-width: $md) {
                width: 80% !important;
                // stylelint-enable declaration-no-important
            }
        }
    }

    .customOverlay {
        background-color: $colour;
        color: $inverted-colour;
        display: flex;
        align-items: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1000;
        padding: 0;

        @media screen and (max-width: $lg) {
            justify-content: center;
        }
    }

    .customModal {
        position: absolute;
        background: none;
        width: 100%;
        max-width: calc(100% - 48px);
        padding: 0;
        margin: 0 auto;
        font-family: $font, $fallback-font, $fallback-font2, $fallback-font3, $fallback-font4;

        .react-responsive-modal-closeButton {
            display: none;
        }

        .modalDetails {
            display: flex;
            position: relative;
            justify-content: center;

            @media screen and (max-width: $lg) {
                flex-direction: column;
            }

            .painting {
                width: 80%;
                margin: 1.5rem 0;
                height: 100%;
                flex: 0 0 62.5%;
                max-width: 62.5%;
                padding: 0 24px;

                @media screen and (max-width: $lg) {
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0;
                }

                .image {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }

            .details {
                width: 100%;
                justify-content: flex-end;
                flex-direction: column;
                display: flex;
                flex: 0 0 37.5%;
                max-width: 37.5%;

                @media screen and (max-width: $lg) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .credit {
                    font-weight: bold;
                }

                .txt {
                    margin: 0;
                }

                .inquireBtn {
                    margin-top: 3rem;
                    height: 50px;
                    width: 200px;
                    border: 1px solid $inverted-colour;
                    text-transform: capitalize;
                    font-size: 16px;
                }

                .actionPanel {
                    display: flex;
                    justify-content: space-between;
                    height: 131px;
                    border-top: 0.5px grey groove;
                    margin-top: 23.5px;
                    font-size: 24px;
                    font-weight: 100;
                    padding: 1.5rem 0;

                    .btnPg {
                        background: transparent;
                        border: 0;
                    }

                    .pagination {
                        display: flex;
                        align-items: center;
                        padding-left: 0;
                        list-style: none;
                        border-radius: 0.25rem;

                        .btnPg {
                            background: transparent;
                            border: 0;
                        }

                        .txt {
                            margin: 0 1rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

// .rgbShiftBackup {
//     display: flex;
//     position: absolute;
//     left: 0;
//     top: 0;
//     opacity: 1;
//     width: 100vw;
//     overflow: hidden;
//     transform-origin: 50% 50%;

//     img {
//         width: 100%;
//         height: 792px;
//         object-fit: cover;

//         @media screen and (max-width: $md) {
//             max-height: 761px;
//         }

//         @media screen and (max-width: $sm) {
//             max-height: 620px;
//         }
//     }
// }

.rgbShiftSlider {
    display: block;
    opacity: 1;
    width: 100%;
    max-height: 700px;
    overflow: hidden;
    position: absolute;

    @media screen and (max-width: $md) {
        top: 76px;
    }

    @media screen and (max-width: $sm) {
        max-height: 580px;
    }

    canvas {
        // stylelint-disable declaration-no-important
        top: 0 !important;
        left: 0 !important;
        transform: none !important;
        width: 100% !important;
        height: 100% !important;
    }

    // Desktop Canvas
    canvas:first-child {
        visibility: hidden;
        margin-top: -180px;
        min-height: 720px;

        @media screen and (min-width: $lg) {
            visibility: inherit;
            margin-top: -2px;
        }

        @media screen and (max-width: $sm) {
            margin-top: -780px;
        }
    }
    // Tablet Canvas
    :nth-child(2) {
        visibility: hidden;
        margin-top: -548px;
        object-fit: unset !important;

        @media screen and (max-width: $lg) and (min-width: $sm) {
            visibility: inherit;
            min-height: 670px;
        }
    }
    // Mobile Canvas
    :last-child {
        visibility: hidden;

        @media screen and (max-width: $sm) {
            visibility: inherit;
            margin-top: -80px;
            object-fit: unset !important;
            min-height: 600px !important;
            // stylelint-enable declaration-no-important
        }
    }
}

.calendly-inline-widget {
    @media screen and (max-width: $md) {
        // stylelint-disable declaration-no-important
        height: 400px !important;
        // stylelint-enable declaration-no-important
    }
}

@import '../../utils/vars';

$background-color: white;

.macpunks {
        /////////HEADER //////////

    .header {
        position: fixed;
        width: 100%;
        z-index: 3;
        transition: all .5s ease-in-out;
        background: transparent;

        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .headerSocialMedia {
                display: flex;
                justify-content: center;
                align-items: center;

                @media(max-width: $xl){
                    margin-top: 30px;
                }
    
                img {
                    height: 25px !important;
                    width: 25px !important;
                }
    
                a {
                    padding: 0 20px;
                    transition: 0.1s ease-in;
                    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

        .mainMenu {
            display: flex;
            list-style: none;
        }
        
        .mainMenu li a {
            display: inline-block;
            padding: 10px 25px;
            text-decoration: none;
            font-family: 'work sans';
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: bold;
            transition: 0.1s ease-in;

            @media(max-width: $xl){
                margin-top: 15px;
            }
        }

        .mainMenu li a:hover {
            transform: scale(1.1);    
        }

        .openMenu {
            font-size:1rem;
            margin: 0 20px;
            display: none;
            cursor: pointer;
        }

        .mainMenu .closeMenu , .icons i {
            font-size: 1rem;
            display: none;
            cursor: pointer;
        }

        .logo {
            margin: 5px 10px;
            padding: 10px 70px 10px 10px;
            font-size: 10px;

            img {
                max-width: 125px;
                @media(max-width: $sm){
                    width: 100px;
                }
            }

        }

    }
        @media(max-width: 1200px){

            nav .mainMenu {
                height: 100vh;
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                z-index: 10;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: top 1s ease;
                display: none;
                background: white;
            }

            nav .mainMenu .closeMenu {
                display: block;
                position: absolute;
                top: 20px;
                right: 20px;
            }

            nav .openMenu {
                display: block;
            }

            nav .mainMenu li a:hover {
                background: none;
                font-size: 1.6rem;
            }
            
            .icons i {
                display: inline-block;
                padding: 12px;
            }
        }

    }

    body {
        scroll-padding-top: 70px; 
    }

     ////////////////////////////////// BANNER //////////////////////////////////

    .banner-hero-wrapper {
        width: 100%;
        height: auto;
        background-color: $background-color;

        .banner-wrapper {
            // max-width: 1780px;
            margin: 0 auto;
            margin-right: auto;
            margin-left:  auto;
            display: flex;
            justify-content: center;
        
            .banner-banner {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                overflow: hidden;
                background-color: $background-color;
                padding-top: 0px;                 

                .banner-desktop {
                    height: 100%;
                    width: 100%;
                    margin-top: -150px;
                    max-width: 100%;
                    text-align: center;
                    display: flex;

                    img {
                        height: 115vh;
                        margin-left: 50%;
                        transform: translateX(-50%);
                        z-index: 1;
                    }
                    
                    @media (max-width: $md) {
                        display: none;
                    }

                    .banner-hero-title {
                        font-family: 'press start';
                        position: absolute;
                        top: 165px;
                        z-index: 2;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 5.5rem;
                        text-transform: uppercase;
                    }
                }
            
                .banner-mobile {
                    width: 100%;
                    height: 115vh;
                    overflow: hidden;
                    max-width: 100%;
                    margin-top: -100px;

                    img {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                }

                    @media (min-width: 769px) {
                        display: none;
                    }

                    @media (max-width: $sm) {
                        margin-top: -25px;
                    }

                    @media (max-width: $iphone678plusw) {
                        margin-top: 0px;
                    }

                    @media (max-width: 320px) {
                        margin-top: 0;
                        height: 115vh;

                    }
                    

                    .banner-hero-title {
                        font-family: 'press start';
                        position: absolute;
                        top: 180px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 4.2rem;
                        text-transform: uppercase;

                        @media (max-width: $sm) {
                            font-size: 3.2rem;
                        }

                        @media (max-width: $iphone678plusw) {
                            font-size: 2.6rem;
                            top: 150px;
                        }

                        @media (max-width: $xs) {
                            font-size: 2.5rem;
                            top: 120px;
                        }

                        @media (max-width: 320px) {
                            font-size: 2.3rem;
                        }
                    }
                }
            
                .banner-hover-button {
                    color: black;
                    position: absolute;
                    top: 260px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #BFBFBF;
                    padding: 10px 25px 10px 25px;
                    box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
                    border-radius: 4px;
                    z-index: 2;

                    @media (max-width: $iphone678plusw) {
                        top: 200px;
                        width: 65%;
                    }

                    @media (max-width: $xs) {
                        top: 180px;
                        width: 65%;
                    }

                    &:hover {
                        background-color: #a6a6a6;
                    }

                    &:active {
                        box-shadow: inset 3px 5px 5px 0px #7F7F7F, inset 0px 4px 5px 2px #000000, inset 5px 5px 5px 0px #FFFFFF;
                    }
                }            
            }
        }
    }



    //////////////////////////////////// INTRO GALLERY ////////////////////////////////// 

    .intro-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;

        @media screen and (max-width: $sm) {
            padding-left: 0;
            padding-right: 0;
        }
      
        .intro-titles {
                text-align: center;
                margin: 150px 0 50px 0; 
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center !important;

                h2 {
                    font-family: 'press start';
                    text-transform: uppercase;
                    font-size: 3.6rem;

                    @media screen and (max-width: $lg) {
                        font-size: 3.4rem;
                    }
    
                    @media screen and (max-width: $sm) {
                        font-size: 3.2rem;
                        margin-top: 30px;
                    }

                    @media screen and (max-width: 414px) {
                        font-size: 2.6rem;
                    }
    
                    @media screen and (max-width: $xs) {
                        font-size: 2.4rem;
                    }
                }

                @media screen and (max-width: $sm) {
                    margin-bottom: 0px;
                }
    
                @media screen and (max-width: $lg) {
                    margin: 50px 0 50px 0; 
                }
                
                
                .intro-byline-copy,
                .intro-subheader {
                    margin-top: 10px;
                    font-family: 'work sans';
                    width: 60%;
                    
                    @media screen and (max-width: $lg) {
                        font-size: 1.5rem;
                    }
                    
                    @media screen and (max-width: $md) {
                        width: 80%;
                    }
                    
                    @media screen and (max-width: $md) {
                        width: 98%;
                        margin-top: 20px;
                        font-size: 1.3rem;
                    }
                    
                    @media screen and (max-width: $xs) {
                        font-size: 1.2rem;
                    }
                    
                }
                .intro-byline-copy {
                    margin-top: 40px;
                    font-size: 1.2rem;

                    @media screen and (max-width: $xl) {
                        width: 80%;
                    }

                    @media screen and (max-width: $lg) {
                        width: 90%;
                        font-size: 1.0rem;
                    }
                    
       
                    
                    
                    @media screen and (max-width: $md) {
                        width: 98%;
                        font-size: 1.3rem;
                    }
                    
                    @media screen and (max-width: $xs) {
                        font-size: 1.2rem;
                    }
                }

            img {
                display: none;
            }
        }
      
        .intro-gallery {
            margin: 0 0 50px 0;
            display: flex;
            justify-content: space-between;
            gap: 40px;
            flex-wrap: wrap;
            max-width: 100%;
            border: 8px solid $background-color;
            border-radius: 45px;
            padding: 40px;
      
            @media screen and (max-width: $xl) {
                gap: 30px;
            }

            @media screen and (max-width: $sm) {
                padding: 0px;
                margin: 0;
            }
        }
    }

////////////////////////////////// GALLERY COMPONENT ////////////////////////////////// 

        .gallery-component {
            position: relative;
            max-width: 100%;
            flex: 1 1 auto;
            width: 20%;
            display: flex;
            flex-direction: column;
            align-content: stretch;
        
            @media screen and (max-width: $xl) {
                width: calc(100% * (1/2) - 50px);
                flex-wrap: wrap;
                margin: 1rem 0;
            }
        
            @media screen and (max-width: $sm) {
                width: calc(100% * (1) - 50px);
                flex-wrap: wrap;
                margin: 0;
            }
        
            .gallery-image-container {
                display: flex;
                justify-content: center;
            }
        
            .gallery-text {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 0.5rem;
                flex-grow: 1;
                background-color: transparent;
                color: black;
                font-family: 'work sans';
                
                h3 {
                    text-align: center;
                    font-size: 2rem;
                }        
                
                p {
                    padding-top: 5px;
                    font-size: 1rem;
                    margin: 0;
                    text-align: center;
                }
            }
        }



        ///////////////// TILES ////////////////////////////////// 

    .tiles-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;

        .tiles-tiles {
            text-align: center;
            font-family: 'work sans';
            $base_font_size: 16px;
            $title_font_size: 1.2rem;
            $ui_line_size: 1px;
            $pad_sm: 0.1rem;
            $pad_md: 0.3rem;
            $pad_lg: 1rem;

            @mixin hidden {
                position: absolute !important;
                clip: rect(1px 1px 1px 1px);
                clip: rect(1px, 1px, 1px, 1px);
            }

            @mixin chequeredBG($color, $size) {
                $sq: $color;
                $sq_size: $size;
                background: linear-gradient(45deg, $sq 25%, transparent 25%, transparent 75%, $sq 75%, $sq), 
                linear-gradient(45deg, $sq 25%, transparent 25%, transparent 75%, $sq 75%, $sq); 
                background-color: #c8c4c4;
                background-size: $sq_size $sq_size;
                background-position: 0 0, $sq_size/2 $sq_size/2;
            }

            .tile-space {
                margin: 40px 0;

                @media screen and (max-width: $sm) {
                    margin: 0px;
                }
            }

            .tiles-row-one,
            .tiles-row-three {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                position: relative;

                .window-left {
                    grid-column: 2 / span 8;
                    grid-row: 1;
                    z-index: 1;

                    @media screen and (max-width: $xl) {
                        grid-column: 2 / span 10;
                    }

                    @media screen and (max-width: $md) {
                        grid-column: 1 / -1;
                    }
                }
                
                .window-right {
                    grid-column: 6 / -1;
                    padding-top: 30%;
                    grid-row: 1;
                    z-index: 2;

                    @media screen and (max-width: $xl) {
                        grid-column: 3 / -1;
                    }

                    @media screen and (max-width: $md) {
                        grid-column: 1 / -1;
                        padding-top: 70%;
                    }
                }
            }

            .tiles-row-two,
            .tiles-row-four {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                position: relative;

                .window-right {
                    grid-column: 2 / span 7;
                    grid-row: 1;
                    z-index: 2;

                    @media screen and (max-width: $xl) {
                        grid-column: 2 / span 10;
                    }

                    @media screen and (max-width: $md) {
                        grid-column: 1 / span 11;
                    }

                    @media screen and (max-width: $md) {
                        grid-column: 1 / -1;
                        padding-top: 70%;
                    }
                }
                
                .window-left {
                    grid-column: 5 / 12;
                    padding-top: 10%;
                    grid-row: 1;
                    z-index: 1;

                    @media screen and (max-width: $xl) {
                        grid-column: 3 / -1;
                    }

                    @media screen and (max-width: $md) {
                        grid-column: 1 / -1;
                        padding-top: 0%;
                    }
                }
            }

        .window {
            flex-direction: column;
            margin: 1rem;
            background-color: #c8c4c4;
            border: 0.1em solid #000;

            @media screen and (max-width: $sm) {
                margin: 0px;
            }
            
            &[data-is-open] {
                display: flex;
            }
            
            &__pane {
                overflow-y: scroll;
                padding: 60px 50px;
                margin-bottom: 2rem;
                background-color: white;

                @media screen and (max-width: $md) {
                    padding: 30px 25px;
                }

                @media screen and (max-width: $sm) {
                    padding: 30px 6px;
                }

                p { 
                    font-size: 1.5rem;

                    @media screen and (max-width: $sm) {
                        font-size: 1.3rem;
                    }
                }
                
                &::-webkit-scrollbar {
                    width: 22px;
                    background-color: #c8c4c4;
                }
                
                &::-webkit-scrollbar-track {
                    @include chequeredBG(#000, 4px);
                    width: 10px;
                    border-left:4px solid black;
                }

                &::-webkit-scrollbar-thumb {
                    width: 20px;
                    box-sizing: content-box;
                    background-color: #c8c4c4;
                    border: $ui_line_size solid black;
                    border-right: none;
                }
            }

            .img-pane {
                padding: 0 !important;
                
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .title-bar {
            flex: none;
            display: flex;
            align-items: center;
            height: 1.5rem;
            margin: $pad_sm/2 0;
            padding: $pad_sm $pad_sm/2;
            
            $lines: 6;
            $p: 100% / 15;
            background: linear-gradient(#000 50%, transparent 50%);
            background-size: $p $p*2;
            background-clip: content-box;
            cursor: default;
            
                .title {
                    padding: 0 0.5em;
                    margin: 0 auto;
                    
                    font-size: $title-font-size;
                    font-weight: bold;
                    line-height: 1.1;
                    text-align: center;
                    background: #c8c4c4;
                    cursor: default;
                }
            }

            .title-bar button {            
                $scale: 0.5;
                $button_scaled_line_size: $ui_line_size * (1 / $scale);
                
                position: relative;
                display: block;
                width: 20px * ( 1 / $scale);
                height: 20px * ( 1 / $scale);
                margin: 0 $pad_sm;
                border: $button_scaled_line_size solid #000;
                outline: clamp($ui_line_size, $button_scaled_line_size, $ui_line_size * 3) solid #64656e;
                background-color: #64656e;
                cursor: pointer;
                
                transform: scale($scale);	

                span {
                    @include hidden();
                }

                &.close {
                    @mixin bg_crossed_lines($w: 30%, $h: $button_scaled_line_size) {
                        
                        $line: #000 0%, #000 100%;
                        $debugline: red 0%, red 100%;

                        background: 
                            linear-gradient($line) left center,
                            linear-gradient($line) right center,
                            linear-gradient($line) center top,
                            linear-gradient($line) center bottom;
                        background-size: 
                            $w $h,
                            $w $h,
                            $h $w,
                            $h $w;
                        background-repeat: no-repeat;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                    }

                    &::before {
                        @include bg_crossed_lines();
                    }

                    &::after {
                        $s: 1.1;
                        $height: $button_scaled_line_size / $s; 

                        @include bg_crossed_lines($w: 22.5%, $h: $height);
                        transform: rotate(45deg) scale($s);
                    }

                    &:active {
                        &::before, &::after {
                            opacity: 1;
                        }
                    }
                }
                
                &.resize {
                    $line: #000 0%, #000 100%;
                    $w: 60%;
                    $h: $button_scaled_line_size;

                    background: 
                        linear-gradient($line) left ($w - 2%),
                        linear-gradient(to bottom, $line) ($w - 2%) top;
                    background-size: 
                        $w $h,
                        $h $w;
                    background-repeat: no-repeat;
                    background-color: #64656e;
                    
                    &:active {
                        background: #64656e;
                    }
                }
            }

            .details-bar {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: $pad_md $pad_md*2;
                border: 0.1rem solid black;
                border-width: 0.1rem 0;
                font-size: 1.2rem;
            }
        }   
            
    }

    ////////////////////////////////// GENIUS BAR ////////////////////////////////// 

    .geniusbar-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .geniusbar-title {
            text-align: center;
            margin: 150px 0 50px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 61px;
            padding-top: 6px;
            max-width: 100%;
            background-color: #E5E5E5;
            box-shadow: inset 5px 0px 5px 0px #FFFFFF, inset 0px -5px 5px 0px #727272, inset 0px 5px 5px 0px #7F7F7F, inset -5px -10px 5px 0px #FFFFFF, inset -3px -5px 5px 0px #B2B2B2;
            border-radius: 4px;

            @media screen and (max-width: $lg) {
                // margin: 100px 0 100px 0;
                height: 57px;
            }

            @media screen and (max-width: $md) {
                height: 52px;
            }

            @media screen and (max-width: $sm) {
                margin: 100px 0 75px 0;
                justify-content: center;
            }

        }

        h2 {
            font-family: 'work sans';
            background-color: #BFBFBF;
            padding: 10px 65px 10px 25px;
            box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
            border-radius: 4px;

            @media screen and (max-width: $sm) {
                padding: 10px 25px;
            }
        }


        .geniusbar {
            width: 80%;
            display: flex;
            align-items: center;
            align-items: space-between;
            gap: 50px;
            margin: 50px 0;

            @media screen and (max-width: $xl) {
                width: 90%;
            }

            @media screen and (max-width: $lg) {
                gap: 20px;
            }

            @media screen and (max-width: $md) {
                flex-direction: column;
            }

        
            // @media screen and (max-width: $sm) {
            //     flex-wrap: wrap;
            //     margin: 0;
            // }
        
            .geniusbar-image-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 1 0;


                @media screen and (max-width: $lg) {
                    width: 48%;
                }  
                @media screen and (max-width: $md) {
                    padding: 20px;
                    width: 100%;
                }

                @media screen and (max-width: $sm) {
                    padding: 0px;
                }
            }
        
            .geniusbar-text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 2 1 0;
                background-color: transparent;
                color: black;

                @media screen and (max-width: $xl) {
                    padding: 0rem;
                }  

                @media screen and (max-width: $lg) {
                    width: 48%;

                }  

                @media screen and (max-width: $md) {
                    width: 100%;

                }  

                p {
                    font-size: 1.2rem;
                    margin-top: 20px;
                    text-align: left;

                    @media screen and (max-width: $xl) {
                        font-size: 1.2rem;
                    }

                    @media screen and (max-width: $md) {
                        text-align: center;
                    }
                }
            }
        }

    }
        
///////////////// MILESTONE BENEFITS ////////////////////////////////// 

    .milestone-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;
      
        .milestone-titles {
            text-align: center;
            margin: 150px 0 150px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 61px;
            padding-top: 6px;
            max-width: 100%;
            background-color: #E5E5E5;
            box-shadow: inset 5px 0px 5px 0px #FFFFFF, inset 0px -5px 5px 0px #727272, inset 0px 5px 5px 0px #7F7F7F, inset -5px -10px 5px 0px #FFFFFF, inset -3px -5px 5px 0px #B2B2B2;
            border-radius: 4px;

            @media screen and (max-width: $lg) {
                margin: 100px 0 100px 0;
                height: 57px;
            }

            @media screen and (max-width: $md) {
                height: 52px;
            }

            @media screen and (max-width: $sm) {
                margin: 50px 0;
            }

            h2 {
                font-family: 'work sans';
                background-color: #BFBFBF;
                padding: 10px 65px 10px 25px;
                box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
                border-radius: 4px;
            }
    
                img {
                    display: none;
                }
        }
      
        .milestone-milestone {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 50px 60px;
            background-position: cover;
            background-repeat: repeat;
            background-size: contain;

            @media screen and (max-width: $sm) {
                padding: 50px 10px;
            }
        }
    }
    
    /////////////////// MILESTONE BENEFITS COMPONENT //////////////////////////////////

    .milestonebenefits-component {
        position: relative;
        margin: 5px 0px;     
        height: auto;
        width: 100%;   
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        padding: 30px 0px 30px 50px;
        font-family: 'work sans'; 
        filter: brightness(90%);

        &:hover {
            filter: brightness(100%);
        }

        @media(max-width: $lg){
            padding-left: 20px;
        }

        @media(max-width: $sm){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            gap: 0px;
        }

        p {
            font-size: 1.6rem;
            line-height: 2rem;

            @media(max-width: $sm){
                font-size: 1.2rem;
                line-height: 1.5rem;
            }
        }
    
        @media(max-width: $xl){
            height: auto;
        }

        @media(max-width: $sm){
            flex-direction: column;
            padding: 10px 10px;
            font-size: 1.2rem;
        }

        .milestonebenefits-number {
            font-family: 'press start';
        
            h2 {
                font-size: 3rem;
                color: #4fabfe;
                min-width: 200px;
            }
        }
        
        .milestonebenefits-component-text {
            display: flex; 
            flex-direction: column;
            align-items: flex-start; 
            align-content: center;  
            line-height: 2.1rem;
            width: 75%;

            p {
                font-size: 1.2rem;
                margin: 0;

                @media(max-width: $lg){
                    font-size: 1.2rem;
                    width: 85%;
                }
                
                @media(max-width: $md){
                    font-size: 1rem;
                    width: 85%;
                }

                @media(max-width: $sm){
                    font-size: 1rem;
                }
            }

            .milestonebenefits-component-title {
                display: none;
            }
    
    
            @media(max-width: $sm){
                width: 99%;
                align-items: center;
            }
    
            @media(max-width: $iphone5w){
                font-size: 1rem;
                line-height: 1.2rem;
            }
        }
        .milestonebenefits-component-text p:first-of-type {
            margin-bottom: 3px;

            @media(max-width: $sm){
                margin-bottom: 15px;
            }
        }
        
    }

    .milestonebenefits-component:nth-of-type( 1 ):hover {
        border: 2px solid #4fabfe !important;
    }

    .milestonebenefits-component:nth-of-type( 2 ) .milestonebenefits-number h2 {
        color: #ff7bef !important;
    }

    .milestonebenefits-component:nth-of-type( 2 ):hover {
        border: 2px solid #ff7bef !important;
    }

    .milestonebenefits-component:nth-of-type( 3 ) .milestonebenefits-number h2 {
        color: #0101a6 !important;
    }

    .milestonebenefits-component:nth-of-type( 3 ):hover {
        border: 2px solid #0101a6 !important;
    }

    .milestonebenefits-component:nth-of-type( 4 ) .milestonebenefits-number h2 {
        color: #ffb510 !important;
    }

    .milestonebenefits-component:nth-of-type( 4 ):hover {
        border: 2px solid #ffb510 !important;
    }

    .milestonebenefits-component:nth-of-type( 5 ) .milestonebenefits-number h2 {
        color: #ff2010 !important;
    }

    .milestonebenefits-component:nth-of-type( 5 ):hover {
        border: 2px solid #ff2010 !important;
    }

    ///////////////// ROADMAP ////////////////////////////////// 

    .roadmap-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-right: 10px;
        padding-left: 10px;
        $base_font_size: 18px;
        $title_font_size: 1.3rem;
        $ui_line_size: 2px;
        $pad_sm: 0.2rem;
        $pad_md: 0.4rem;
        $pad_lg: 1rem;

        @mixin hidden {
            position: absolute !important;
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
        }

        @mixin chequeredBG($color, $size) {
            $sq: $color;
            $sq_size: $size;
            background: linear-gradient(45deg, $sq 25%, transparent 25%, transparent 75%, $sq 75%, $sq), 
            linear-gradient(45deg, $sq 25%, transparent 25%, transparent 75%, $sq 75%, $sq); 
            background-color: #c8c4c4;
            background-size: $sq_size $sq_size;
            background-position: 0 0, $sq_size/2 $sq_size/2;
        }

        .roadmap-title {
            text-align: center;
            margin: 150px 0 50px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 61px;
            padding-top: 6px;
            max-width: 100%;
            background-color: #E5E5E5;
            box-shadow: inset 5px 0px 5px 0px #FFFFFF, inset 0px -5px 5px 0px #727272, inset 0px 5px 5px 0px #7F7F7F, inset -5px -10px 5px 0px #FFFFFF, inset -3px -5px 5px 0px #B2B2B2;
            border-radius: 4px;

            @media screen and (max-width: $lg) {
                // margin: 100px 0 100px 0;
                height: 57px;
            }

            @media screen and (max-width: $md) {
                height: 52px;
            }

            @media screen and (max-width: $sm) {
                margin: 100px 0 75px 0;
                justify-content: center;
            }

            h2 {
                font-family: 'work sans';
                background-color: #BFBFBF;
                padding: 10px 65px 10px 25px;
                box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
                border-radius: 4px;

                @media screen and (max-width: $sm) {
                    padding: 10px 25px;
            }
            }
        }

            .roadmap-container {
                margin: 0px 0px 50px 0;
                width: 70%;
                max-width: 1150px;
                position: relative;

                @media screen and (max-width: $lg) {
                    width: 85%;
                }

                @media screen and (max-width: $md) {
                    width: 100%;
                }

                @media screen and (max-width: $sm) {
                    // margin: 100px 0px;
                    width: 100%;
                }


                .border {
                    background-color: #50acfc;      
                    width: 50px;
                    height: 80%;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    z-index: -1;
                    margin: 50px 0;
                }
            }

        .window {
            flex-direction: column;
            margin: 1rem;
            background-color: #c8c4c4;
            border: 0.1em solid #000;
            text-align: center;
            z-index: 12;

            @media screen and (max-width: $sm) {
                margin: 10px 0;
            }
            
            &[data-is-open] {
                display: flex;
            }
            
            &__pane {
                overflow-y: scroll;
                padding: 50px 25px;
                margin-bottom: 2rem;
                background-color: white;

                @media screen and (max-width: $md) {
                    padding: 40px 25px;
                }

                @media screen and (max-width: $sm) {
                    padding: 30px 6px;
                }

                p { 
                    font-size: 1.3rem;
                    margin-bottom: 5px;

                    @media screen and (max-width: $sm) {
                        font-size: 1.2rem;
                        margin-bottom: 10px;
                    }
                }
                
                &::-webkit-scrollbar {
                    width: 22px;
                    background-color: #c8c4c4;
                }
                
                &::-webkit-scrollbar-track {
                    @include chequeredBG(#000, 4px);
                    width: 10px;
                    border-left:4px solid black;
                }

                &::-webkit-scrollbar-thumb {
                    width: 20px;
                    box-sizing: content-box;
                    background-color: #c8c4c4;
                    border: $ui_line_size solid black;
                    border-right: none;
                }
            }
        }

        .title-bar {
            flex: none;
            display: flex;
            align-items: center;
            height: 1.5rem;
            margin: $pad_sm/2 0;
            padding: $pad_sm $pad_sm/2;
            
            $lines: 6;
            $p: 100% / 15;
            background: linear-gradient(#000 50%, transparent 50%);
            background-size: $p $p*2;
            background-clip: content-box;
            cursor: default;
            
                .title {
                    padding: 0 0.5em;
                    margin: 0 auto;
                    
                    font-size: $title-font-size;
                    font-weight: bold;
                    line-height: 1.1;
                    text-align: center;
                    background: #c8c4c4;
                    cursor: default;
                }
            }

            .title-bar button {            
                $scale: 0.5;
                $button_scaled_line_size: $ui_line_size * (1 / $scale);
                
                position: relative;
                display: block;
                width: 20px * ( 1 / $scale);
                height: 20px * ( 1 / $scale);
                margin: 0 $pad_sm;
                border: $button_scaled_line_size solid #000;
                outline: clamp($ui_line_size, $button_scaled_line_size, $ui_line_size * 3) solid #64656e;
                background-color: #64656e;
                cursor: pointer;
                
                transform: scale($scale);	

                span {
                    @include hidden();
                }

                &.close {
                    @mixin bg_crossed_lines($w: 30%, $h: $button_scaled_line_size) {
                        
                        $line: #000 0%, #000 100%;
                        $debugline: red 0%, red 100%;

                        background: 
                            linear-gradient($line) left center,
                            linear-gradient($line) right center,
                            linear-gradient($line) center top,
                            linear-gradient($line) center bottom;
                        background-size: 
                            $w $h,
                            $w $h,
                            $h $w,
                            $h $w;
                        background-repeat: no-repeat;
                    }

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                    }

                    &::before {
                        @include bg_crossed_lines();
                    }

                    &::after {
                        $s: 1.1;
                        $height: $button_scaled_line_size / $s; 

                        @include bg_crossed_lines($w: 22.5%, $h: $height);
                        transform: rotate(45deg) scale($s);
                    }

                    &:active {
                        &::before, &::after {
                            opacity: 1;
                        }
                    }
                }
                
                &.resize {
                    $line: #000 0%, #000 100%;
                    $w: 60%;
                    $h: $button_scaled_line_size;

                    background: 
                        linear-gradient($line) left ($w - 2%),
                        linear-gradient(to bottom, $line) ($w - 2%) top;
                    background-size: 
                        $w $h,
                        $h $w;
                    background-repeat: no-repeat;
                    background-color: #64656e;
                    
                    &:active {
                        background: #64656e;
                    }
                }
            }

            .details-bar {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: $pad_md $pad_md*2;
                border: 0.1rem solid black;
                border-width: 0.1rem 0;
                font-size: 1.2rem;
            }
            
    }
///////////////////////////////////// VIDEO //////////////////////////////////

.video-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left:  auto;
        padding-right: 10px; 
        padding-left:  10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: $sm) {
            padding-left: 0;
            padding-right: 0;
        }

        .video-titles {
            display: none;
        
            h2 {
                display: none;
            }
        }

        .video-gallery {
            margin-top: 50px;
            max-width: 100%;
            width: 80%;
            height: 80vh;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: $xxl) {
                width: 80%;
            }

            @media screen and (max-width: $xl) {
                width: 100%;
            }

            @media screen and (max-width: $sm) {
                height: 50vh;
            }
        }
        .react-player {
            max-width: 100%;
            height: 100%;
        }
    }

    //////////////////////////////////// TEAM GALLERY ////////////////////////////////// 

    .team-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;

        @media screen and (max-width: $sm) {
            padding-left: 0;
            padding-right: 0;
        }
      
        .team-titles {
            text-align: center;
            margin: 150px 0 150px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            width: 100%;
            height: 61px;
            padding-top: 6px;
            max-width: 100%;
            background-color: #E5E5E5;
            box-shadow: inset 5px 0px 5px 0px #FFFFFF, inset 0px -5px 5px 0px #727272, inset 0px 5px 5px 0px #7F7F7F, inset -5px -10px 5px 0px #FFFFFF, inset -3px -5px 5px 0px #B2B2B2;
            border-radius: 4px;

            @media screen and (max-width: $lg) {
                margin: 100px 0 100px 0;
                height: 57px;
            }

            @media screen and (max-width: $md) {
                height: 52px;
            }

            @media screen and (max-width: $sm) {
                margin: 50px 0;
            }

            h2 {
                font-family: 'work sans';
                background-color: #BFBFBF;
                padding: 10px 65px 10px 25px;
                box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
                border-radius: 4px;
            }
    
                img {
                    display: none;
                }
                  
                .team-byline-copy,
                .team-subheader {
                    margin-top: 10px;
                    font-family: 'work sans';
                    width: 60%;
                    
                    @media screen and (max-width: $lg) {
                        font-size: 1.5rem;
                    }
                    
                    @media screen and (max-width: $md) {
                        width: 80%;
                    }
                    
                    @media screen and (max-width: $md) {
                        width: 98%;
                        margin-top: 20px;
                        font-size: 1.3rem;
                    }
                    
                    @media screen and (max-width: $xs) {
                        font-size: 1.2rem;
                    }
                    
                }

                .team-byline-copy {
                    margin-top: 40px;
                    font-size: 1.5rem;

                    @media screen and (max-width: $xl) {
                        width: 80%;
                    }

                    @media screen and (max-width: $lg) {
                        width: 90%;
                        font-size: 1.4rem;
                    }
                    
                    
                    @media screen and (max-width: $md) {
                        width: 98%;
                        font-size: 1.3rem;
                    }
                    
                    @media screen and (max-width: $xs) {
                        font-size: 1.2rem;
                    }
                }

            img {
                display: none;
            }
        }
      
        .team-gallery {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            flex-wrap: wrap;
            max-width: 100%;
            border: 8px solid $background-color;
            border-radius: 45px;
            padding: 40px;
      
            @media screen and (max-width: $xl) {
                gap: 30px;
                padding: 20px;
            }

            @media screen and (max-width: $lg) {
                padding: 0px;
            }
        }
    }

////////////////////////////////// TEAM COMPONENT ////////////////////////////////// 

        .team-component {
            position: relative;
            flex: 1 1 auto;
            width: 80%;
            max-width: 1200px;
            display: flex;
            align-items: center;
            gap: 20px;

            @media screen and (max-width: $xl) {
                width: 100%;
            }

            @media screen and (max-width: $md) {
                flex-direction: column;
            }
    
        
            @media screen and (max-width: $sm) {
                flex-wrap: wrap;
                margin: 0;
            }
        
            .team-image-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 1 0;

                @media screen and (max-width: $md) {
                    padding: 20px;
                }

                @media screen and (max-width: $sm) {
                    padding: 0px;
                }
            }
        
            .team-text {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 2 1 0;
                background-color: transparent;
                color: black;
                font-family: 'work sans';

                @media screen and (max-width: $xl) {
                    padding: 0rem;
                }
                
                h3 {
                    text-align: left;
                    font-size: 2rem;

                    @media screen and (max-width: $md) {
                        text-align: center;
                    }
                }       
                
                h4 {

                    @media screen and (max-width: $md) {
                        text-align: center;
                    }
                }
                
                p {
                    padding-top: 10px;
                    font-size: 1.2rem;
                    margin: 0;
                    text-align: left;

                    @media screen and (max-width: $lg) {
                        font-size: 1rem;
                    }

                    @media screen and (max-width: $md) {
                        text-align: center;
                    }
                }
            }
        }





///////////////////////////////////// ABOUT ////////////////////////////////// 

    .about-wrapper {
        max-width: 1624px;
        margin: 0 auto;
        margin-right: auto;
        margin-left:  auto;
        padding-right: 10px; 
        padding-left:  10px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: $sm) {
            padding-left: 0;
            padding-right: 0;
        }

        .about-about {
            width: 80%;
            display: flex;
            align-items: flex-start;
            gap: 80px;
            line-height: 1.7rem;
            margin: 10rem 0;
            padding: 45px 0;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(217,217,217,1) 100%);
            border: 2px solid grey;

            @media screen and (max-width: $xl) {
                width: 95%;
            }

            @media screen and (max-width: $lg) {
                gap: 40px;
            }

            @media screen and (max-width: $md) {
                flex-direction: column;
                align-items: center;
            }

            @media screen and (max-width: $sm) {
                margin: 5rem 0;

            }

            .about-image {
                width: 100%;
                max-width: 100%;
                height: 100%;
                padding: 50px 20px 50px 100px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1400px) {
                    padding: 25px 10px 25px 50px;
                    flex: 50%;
                }
                @media screen and (max-width: $lg) {
                    flex: 50%;
                }

                @media screen and (max-width: $md) {
                    flex: 100%;
                    padding: 40px 140px;
                }

                @media screen and (max-width: $sm) {
                    padding: 20px 70px;
                }
            }

            .about-description {
                padding: 20px 20px 20px 0;
                line-height: 2rem;
                
                p {
                    width: 85%;
                    font-size: 1.4rem;
                    margin: 20px 0 40px 0;
                }
                @media screen and (max-width: 1400px) {
                    flex: 100%;
                }

                @media screen and (max-width: $lg) {
                    flex: 100%;

                    p {
                        width: 100%;
                    }
                }

                @media screen and (max-width: $md) {
                    text-align: center;
                    padding: 20px;
                }

                @media screen and (max-width: $sm) {
                    text-align: center;
                    padding: 0;
                }

                .about-title {
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-family: "work sans"
                }
            }
        }
    }

////////////////////////////////////// ABOUT DESCRIPTION ////////////////////////////////// 


    .about-description {
        font-family: "work sans";

        .about-description-link {
            text-decoration: none;
            padding: 15px 45px;
            font-size: 1.5rem;
            margin: auto;
            text-align: center;
            background-color: #BFBFBF;
            padding: 10px 25px;
            box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
            border-radius: 5px;
        
            @media screen and (max-width: $sm) {
                font-size: 1.2rem;
                width: 100%;
            }

            &:hover {
                background-color: #a6a6a6;
            }

            &:active {
                box-shadow: inset 3px 5px 5px 0px #7F7F7F, inset 0px 4px 5px 2px #000000, inset 5px 5px 5px 0px #FFFFFF;
            }
        }
    }


    /////// CALL TO ACTION BUTTON /////
    .footer-cta {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin: 100px 0 0px 0;
        
        a {
            text-decoration: none;
            font-size: 1.2rem;
        }

        .banner-hover-button {
            text-transform: uppercase;
            color: black;
            background-color: #BFBFBF;
            padding: 20px 25px 20px 25px;
            box-shadow: inset -3px -5px 5px 0px #7F7F7F, inset 0px -4px 5px -2px #000000, inset 5px 5px 5px 0px #FFFFFF;
            border-radius: 4px;
            z-index: 2;


            @media (max-width: $iphone678plusw) {
                top: 200px;
                width: 65%;
            }

            @media (max-width: $xs) {
                top: 180px;
                width: 65%;
            }

            &:hover {
                background-color: #a6a6a6;
            }

            &:active {
                box-shadow: inset 3px 5px 5px 0px #7F7F7F, inset 0px 4px 5px 2px #000000, inset 5px 5px 5px 0px #FFFFFF;
            }
        }         
    }



    ///////////////////////// FOOTER ///////////////////



    .footer {
        padding: 50px 0 5px 0;
        background-color: #d3d3d3;

        img {
            width: 150px;
        }
    
        .wrapper {
            max-width: 1924px;
            margin: 0 auto;
            width: 100%;
            padding: 0 25px 0 15px;
            
            .footerInfo {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 50px;
    
                li {
                    list-style: none;
                }
    
                a {
                    text-decoration: none;
                }
    
                @media screen and (max-width: $xl) {
                    flex-direction: column;
                    align-items: center;
                }
    
                .logo {
                    padding: 0px 40px;
                    font-size: 2rem;
                    flex-grow: 1;
                    display: flex;
                    justify-content: flex-start;
    
                    @media screen and (max-width: $xxl) {
                        padding: 0px 40px;
                    }
    
                    @media screen and (max-width: $xl) {
                        padding: 0px 20px;
                    }
    
                    h4 {
                        margin: 10px;
                    }
                }
            }
    
            .socialMedia {
                display: flex;
                flex-grow: 2;
    
                img {
                    height: 40px !important;
                    width: 40px !important;
                }
    
                a {
                    padding: 0 20px;
                    transition: 0.1s ease-in;
                    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
    
            .nav {
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: bold;
                font-family: 'work sans';
                flex-grow: 4;
                display: flex;
                justify-content: flex-end;
                width: 100%;

                @media screen and (max-width: $xl) {
                    justify-content: center;

                }
    
                ul {
                    display: flex;
                    justify-content: space-between;
                    gap: 40px;
                    width: 70%;

                    @media screen and (max-width: $xxl) {
                        width: 100%;
                    }

                    @media screen and (max-width: $xl) {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 0px;
                        width: 100%;
                    }
    
                    li {
                        @media screen and (max-width: $md) {
                            text-align: center;
                        }
                    }
                }
            }

            .copyright {
                text-align: center;
                margin-top: 50px;
    
                p {
                    font-size: 0.8rem;
                }
            }
            
        }
    }
}
